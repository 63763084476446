import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import moment from 'moment';
export default {
  data: function data() {
    return {
      tableHeight: '550',
      columns: [{
        title: '起始时间',
        dataIndex: 'startTime',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'startTime'
        }
      }, {
        title: '结束时间',
        dataIndex: 'endTime',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'endTime'
        }
      }, {
        title: '单价（元）',
        dataIndex: 'price',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'price'
        }
      }, {
        title: '时段',
        dataIndex: 'type',
        width: 100,
        align: 'center',
        scopedSlots: {
          customRender: 'type'
        }
      }, {
        title: '操作',
        key: 'action',
        width: 90,
        align: 'center',
        scopedSlots: {
          customRender: 'action'
        }
      }],
      form: {
        id: null,
        description: "",
        dischargeLowerLimit: null,
        dischargeUpperLimit: null,
        name: '',
        details: []
      },
      modelTitle: '',
      keyboard: false,
      type: 0,
      timePriceChart: null,
      modelShowTimePrice: false,
      timePriceList: [],
      timePriceData: [],
      timePriceOption: null,
      timePriceDescription: [{
        name: "尖时段",
        id: 1
      }, {
        name: "峰时段",
        id: 2
      }, {
        name: "平时段",
        id: 3
      }, {
        name: "谷时段",
        id: 4
      }],
      localTimePriceParam: {
        id: -1,
        stationId: -1,
        stationName: ''
      },
      timePrice: {
        id: null,
        address: "",
        status: false,
        details: []
      },
      timePriceForm: {
        id: null,
        address: "",
        source: null,
        status: false,
        details: []
      },
      timePriceId: -1,
      data: []
    };
  },
  destroyed: function destroyed() {},
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this_.localTimePriceParam = JSON.parse(localStorage.getItem("timePrice"));

    if (this_.localTimePriceParam == null) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    if (this_.localTimePriceParam.id === null) {
      this_.$confirm({
        title: "创建时段电价",
        content: this_.localTimePriceParam.stationName + '还没有创建时段电价, 请先为' + this_.localTimePriceParam.stationName + "创建时段电价",
        okText: '确认',
        cancelText: '取消',
        onOk: function onOk() {
          this_.create();
        }
      });
    } else {
      this_.getTimePrice();
    }
  },
  methods: {
    beforeCloseHandel: function beforeCloseHandel() {
      var this_ = this;
      this_.operationType = 0;
      this_.modelShowTimePrice = false;
    },
    create: function create() {
      var this_ = this;
      this_.timePriceForm.id = null;
      this_.timePriceForm.address = null;
      this_.timePriceForm.details = [];
      this_.modelShowTimePrice = true;
      this_.ifAdd_ = 1;
      this_.modelTitle = "添加时段电价";

      if (this_.timePriceData.length < 1) {
        var obj = {
          startTime: '00:00',
          endTime: '24:00',
          price: '',
          type: '',
          if_change: "none"
        };
        obj.key = 1;
        this_.timePriceData.push(obj);
      }
    },
    changeFn: function changeFn(record) {
      var n = record.key - 1;

      if (this.timePriceData[this.timePriceData.length - 1].endTime != '24:00') {
        var obj = {
          startTime: this.timePriceData[n].endTime,
          endTime: '24:00',
          price: '',
          type: '',
          if_change: "none"
        };
        obj.key = this.timePriceData.length + 1;
        this.timePriceData.push(obj);
      } else {
        this.timePriceData[n + 1].startTime == this.timePriceData[n].endTime;
      }

      if (this.timePriceData.length == 2) {
        if (n == 0) {
          if (this.timePriceData[n].endTime != this.timePriceData[n + 1].startTime) {
            this.timePriceData[n + 1].startTime = this.timePriceData[n].endTime;
          }
        } else {
          if (this.timePriceData[n].startTime != this.timePriceData[n - 1].endTime) {
            this.timePriceData[n - 1].endTime = this.timePriceData[n].startTime;
          }
        }
      } else if (this.timePriceData.length > 2) {
        if (n == 0) {
          if (this.timePriceData[n].endTime != this.timePriceData[n + 1].startTime) {
            this.timePriceData[n + 1].startTime = this.timePriceData[n].endTime;
          }
        } else if (n > 0 && n < this.timePriceData.length - 1) {
          if (this.timePriceData[n].endTime != this.timePriceData[n + 1].startTime) {
            this.timePriceData[n + 1].startTime = this.timePriceData[n].endTime;
          }

          if (this.timePriceData[n].startTime != this.timePriceData[n - 1].endTime) {
            this.timePriceData[n - 1].endTime = this.timePriceData[n].startTime;
          }
        } else {
          if (this.timePriceData[n].startTime != this.timePriceData[n - 1].endTime) {
            this.timePriceData[n - 1].endTime = this.timePriceData[n].startTime;
          }
        }
      }
    },
    update: function update() {
      var this_ = this;

      if (this_.timePrice.id == "" || this_.timePrice.id == null) {
        this_.$message.info('您还没有选择需要修改的时段电价！', 2, function () {});
        return;
      }

      this_.timePriceData = [];
      this_.timePriceForm.id = this_.timePrice.id;
      this_.timePriceForm.address = this_.timePrice.address;
      this_.timePriceForm.status = this_.timePrice.status;
      this_.timePriceForm.details = [];
      this_.modelShowTimePrice = true;
      this_.ifAdd_ = 2;
      this_.modelTitle = "修改时段电价";
      this_.timePriceForm.address = this_.timePrice.address;

      for (var i = 0; i < this_.timePrice.details.length; i++) {
        var obj = {
          startTime: new Date(this_.timePrice.details[i][0].toString().replace(/-/g, "/")).Format("hh:mm"),
          endTime: new Date(this_.timePrice.details[i][1].toString().replace(/-/g, "/")).Format("hh:mm"),
          price: this_.timePrice.details[i][2],
          type: this_.timePrice.details[i][3],
          if_change: "none"
        };
        obj.key = i + 1;
        this_.timePriceData.push(obj);
      }

      this_.timePriceData[this_.timePriceData.length - 1].endTime = '24:00';
    },
    reflush: function reflush() {
      var this_ = this;
      var newOp = this.getOption();
      this_.parse();
      newOp.series[0].data = this_.data;
      this.timePriceChart = this_.$echarts.init(document.getElementById("time_price_container"));
      this.timePriceChart.setOption(newOp);
      setTimeout(function () {
        window.onresize = function () {
          this_.timePriceChart.resize();
        };
      }, 200);
    },
    getTimePrice: function getTimePrice() {
      var this_ = this;
      var date = new Date();
      axios.get('/emind/config/time/price/get/' + this_.localTimePriceParam.id).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === "0") {
          this_.timePrice.address = d.data.data.address;
          this_.timePrice.id = d.data.data.id;
          this_.timePrice.status = d.data.data.status;
          this_.timePrice.details = [];

          for (var i = 0; i < d.data.data.details.length; i++) {
            this_.data.push([date.Format('yyyy-MM-dd ') + d.data.data.details[i].startTime.split(" ")[1], date.Format('yyyy-MM-dd ') + d.data.data.details[i].endTime.split(" ")[1], d.data.data.details[i].price, d.data.data.details[i].type, d.data.data.details[i].description]);
            this_.timePrice.details.push([date.Format('yyyy-MM-dd ') + d.data.data.details[i].startTime.split(" ")[1], date.Format('yyyy-MM-dd ') + d.data.data.details[i].endTime.split(" ")[1], d.data.data.details[i].price, d.data.data.details[i].type, d.data.data.details[i].description]);
          }

          this_.timePriceId = this_.timePrice.id;
          this_.reflush();
        } else {
          this_.$message.error(d.data.message, 2, function () {});
        }
      }).catch(function (error) {
        console.warn(error);

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    closeFn: function closeFn() {
      this.modelShowTimePrice = false;
    },
    addFn: function addFn() {
      var this_ = this;
      var obj = {
        startTime: '00:00',
        endTime: '24:00',
        price: '',
        type: '',
        if_change: "none"
      };
      obj.key = this_.timePriceData.length + 1;
      this_.timePriceData.push(obj);
    },
    okFn: function okFn() {
      var this_ = this;

      if (this_.timePriceForm.address == "" || this_.timePriceForm.address == null) {
        this.$message.warning('时段电价所属省/市不能为空');
        return;
      }

      if (this_.timePriceData.length < 1) {
        this.$message.warning('请添加详细时段电价');
        return;
      } else if (this_.timePriceData.length == 1) {
        this.$message.warning('详细时段电价需要包含全天24小时,请填写完整之后再提交');
        return;
      }

      this_.timePriceForm.details = [];
      var endTime = null;

      for (var i = 0; i < this_.timePriceData.length; i++) {
        if (i > 0 && endTime != this_.timePriceData[i].startTime) {
          break;
        }

        endTime = this_.timePriceData[i].endTime;
        var obj = {
          'startTime': this_.timePriceData[i].startTime,
          'endTime': this_.timePriceData[i].endTime,
          'price': parseFloat(this_.timePriceData[i].price),
          'type': this_.timePriceData[i].type,
          'description': '',
          'timeIndex': 0
        };

        if (obj.price == null) {
          this.$message.warning('请为所有时段电价详情输入电价！');
          return;
        }

        if (obj.type == 1) {
          obj.description = '尖时段';
        } else if (obj.type == 2) {
          obj.description = '峰时段';
        } else if (obj.type == 3) {
          obj.description = '平时段';
        } else if (obj.type == 4) {
          obj.description = '谷时段';
        } else {
          this.$message.warning('请为所有时段电价详情选择时段！');
          return;
        }

        obj.startTime = "2019-10-01 " + obj.startTime + ":00";
        obj.endTime = "2019-10-01 " + obj.endTime + ":00";
        this_.timePriceForm.details.push(obj);

        if (endTime === '00:00') {
          break;
        }
      }

      if (endTime != '24:00') {
        this_.$message.info('详细时段电价需要包含全天24小时,请填写完整之后再提交', 2, function () {});
        return;
      }

      var data = this_.timePriceForm;
      data.stationId = this_.localTimePriceParam.stationId;
      data.details[data.details.length - 1].endTime = new Date().Format('yyyy-MM-dd ') + '23:59:59';

      if (this_.ifAdd_ == 1) {
        axios({
          method: 'post',
          url: '/emind/config/time/price/creation',
          data: data
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === "0") {
            obj.id = d.message;
            this_.$message.success("添加成功");
            this_.localTimePriceParam.id = d.data.data;
            this_.timePriceId = this_.localTimePriceParam.id;
            localStorage.setItem("timePrice", JSON.stringify(this_.localTimePriceParam));
            setTimeout(function () {
              for (var i = 0; i < this_.timePriceList.length; i++) {
                if (this_.timePriceList[i].id > this_.timePriceId) {
                  this_.timePriceId = this_.timePriceList[i].id;
                }
              }

              this_.change();
            }, 100);
            this_.modelShowTimePrice = false;
          } else if (d.data.code === '401') {
            this_.modelShowTimePrice = false;
            this_.$message.warning('当前账户没有创建时段电价的操作权限');
          } else {
            this_.$message.error(d.data.message, 2, function () {});
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else {
        data.id = this_.timePrice.id;
        data.address = this_.timePrice.address;
        data.status = this_.timePrice.status;

        if (data.status) {
          data.status = 1;
        } else {
          data.status = 0;
        }

        axios({
          method: 'put',
          url: '/emind/config/time/price/modification',
          data: data
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === "0") {
            this_.$message.success("修改成功");
            this_.modelShowTimePrice = false;
            setTimeout(function () {
              this_.change();
            }, 100);
          } else if (d.data.code === '401') {
            this_.modelShowTimePrice = false;
            this_.$message.warning('当前账户没有修改时段电价的操作权限');
          } else {
            this_.$message.error(d.data.message, 2, function () {});
          }
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      }

      setTimeout(function () {
        this_.modelShowTimePrice = false;
      }, 100);
    },
    change: function change() {
      var this_ = this;
      var date = new Date();
      this_.data.splice(0, this_.data.length);

      if (this_.timePriceId == null) {
        setTimeout(function () {
          this_.reflush();
        }, 500);
        return;
      }

      axios.get('/emind/config/time/price/get/' + this_.timePriceId).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === "0") {
          this_.timePrice.address = d.data.data.address;
          this_.timePrice.id = d.data.data.id;
          this_.timePrice.status = d.data.data.status;
          this_.timePrice.details = [];

          for (var i = 0; i < d.data.data.details.length; i++) {
            this_.data.push([date.Format('yyyy-MM-dd ') + d.data.data.details[i].startTime.split(" ")[1], date.Format('yyyy-MM-dd ') + d.data.data.details[i].endTime.split(" ")[1], d.data.data.details[i].price, d.data.data.details[i].type, d.data.data.details[i].description]);
            this_.timePrice.details.push([date.Format('yyyy-MM-dd ') + d.data.data.details[i].startTime.split(" ")[1], date.Format('yyyy-MM-dd ') + d.data.data.details[i].endTime.split(" ")[1], d.data.data.details[i].price, d.data.data.details[i].type, d.data.data.details[i].description]);
          }

          this_.reflush();
        } else {
          this_.$message.error(d.data.message, 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    delFn: function delFn() {
      var this_ = this;

      if (this_.timePriceId == "" || this_.timePriceId == null) {
        this_.$message.info('您还没有选择需要删除的电价设定！', 2, function () {});
        return;
      }

      if (this_.timePrice.status) {
        this_.$message.error('该电价正在使用中，不能删除', 2, function () {});
        return;
      }

      this_.$confirm('此操作将永久删除该电价设定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        axios.delete('/emind/config/time/price/deletion/' + this_.timePriceId).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === "0") {
            this_.data = [];

            if (this_.timePriceList.length > 1) {
              for (var i = 0; i < this_.timePriceList.length; i++) {
                if (this_.timePriceList[i].id != this_.timePriceId) {
                  this_.timePriceId = this_.timePriceList[i].id;
                  break;
                }
              }

              this_.change();
            } else {
              this_.timePriceId = null;
            }

            this_.$message({
              message: "删除成功",
              type: 'success'
            });
          } else {
            this_.$message.error(d.data.message, 2, function () {});
          }
        });
        setTimeout(function () {
          this_.change();
        }, 350);
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    del_msg_fn: function del_msg_fn(record) {
      var n = record.key - 1;
      var this_ = this;

      if (this_.timePriceData.length < 2) {
        this_.timePriceData[0].startTime = "";
        this_.timePriceData[0].endTime = "";
        this_.timePriceData[0].price = "";
        this_.timePriceData[0].description = "";
      } else {
        this_.timePriceData.splice(n, 1);
      }

      if (n == 0) {
        this_.timePriceData[0].startTime = '00:00';
      } else if (n == this_.timePriceData.length) {
        this_.timePriceData[this_.timePriceData.length - 1].endTime = '24:00';
      } else {
        this_.timePriceData[n].startTime = this_.timePriceData[n - 1].endTime;
      }

      for (var i = 0; i < this_.timePriceData.length; i++) {
        this_.timePriceData[i].key = i + 1;
      }
    },
    getOption: function getOption() {
      var this_ = this;
      return {
        tooltip: {},
        xAxis: {
          scale: true,
          type: 'time',
          splitLine: {
            show: false
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#7C868D'
            }
          },
          min: new Date(new Date(new Date().Format("yyyy/MM/dd")).getTime()).Format("yyyy-MM-dd hh:mm:ss"),
          max: new Date(new Date(new Date().Format("yyyy/MM/dd")).getTime() + 86400000 - 1000).Format("yyyy-MM-dd hh:mm:ss"),
          axisLabel: {
            formatter: function formatter(params, index) {
              var currentYear = moment().format("YYYY");
              var currentMonth = moment().format("MM");
              var currentDay = moment().format("DD");
              var thisYear = moment(Number(params)).format("YYYY");
              var thisMonth = moment(Number(params)).format("MM");
              var thisDay = moment(Number(params)).format("DD");
              var hourAndMinite = moment(Number(params)).format("HH:mm");
              return hourAndMinite;
            }
          }
        },
        grid: {
          left: 50,
          right: 60,
          top: '35',
          height: '80%'
        },
        yAxis: {
          axisLine: {
            show: false,
            lineStyle: {
              color: '#7C868D'
            }
          }
        },
        series: [{
          type: 'custom',
          renderItem: this_.timePriceRenderItem,
          label: {
            normal: {
              show: true,
              position: 'top'
            }
          },
          dimensions: ['开始时间 ', '结束时间 ', '电价 '],
          encode: {
            x: [0, 1],
            y: 2,
            tooltip: [0, 1, 2],
            itemName: 4
          },
          data: this_.data
        }]
      };
    },
    timePriceRenderItem: function timePriceRenderItem(params, api) {
      var yValue = api.value(2);
      var start = api.coord([api.value(0), yValue]);
      var size = api.size([api.value(1) - api.value(0), yValue]);
      var style = api.style();
      return {
        type: 'rect',
        shape: {
          x: start[0],
          y: start[1],
          width: size[0],
          height: size[1]
        },
        style: style
      };
    },
    parse: function parse() {
      this.data = this.$echarts.util.map(this.data, function (item, index) {
        var col = "";

        if (item[2] < 0.5) {
          col = "#49AA19";
        } else if (item[2] < 0.8) {
          col = "#CCBA03";
        } else if (item[2] < 1.2) {
          col = "#F26813";
        } else {
          col = "#A61D24";
        }

        return {
          value: item,
          itemStyle: {
            normal: {
              color: col
            }
          }
        };
      });
    }
  }
};